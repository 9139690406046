<script lang="ts">
  import { fetchMessageLoading, sendMessageLoading, messageList, botInfo } from "./store";
  import { afterUpdate } from "svelte";
  import MessageRowComponent from "./MessageRowComponent.svelte";
  import ChatForm from "./ChatForm.svelte";
  import { type Message, type QuickReply } from "./service/service";
  import SkeletonView from "./SharedComponent/SkeletonView.svelte";
  import QuickReplyContainer from "./QuickReplyContainer.svelte";
  import LogoLoader from "./SharedComponent/LogoLoader.svelte";
  import { ENV } from "./config/config";
  let messageListData: Message[] = [];
  let messageContainer: HTMLDivElement | null = null;

  let widgetIsActive: boolean = false;
  export let aiMessageConverter: any;
  export let userMessageConverter: any;

  messageList.subscribe((list) => {
    messageListData = list;
  });
  let formQuickReplyHandler: any;

  const disableBodyScroll = () => {
    if (window !== undefined) {
      window.document.body.style.overflow = "hidden";
      widgetIsActive = true;
    }
  };

  const enableBodyScroll = () => {
    if (window !== undefined) {
      window.document.body.style.overflow = "auto";
      widgetIsActive = false;
    }
  };

  afterUpdate(() => {
    scrollToBottom();
  });

  function scrollToBottom() {
    if (messageContainer) {
      messageContainer.scrollTo({
        top: messageContainer.scrollHeight,
        behavior: "smooth",
      });
    }
  }

  function sendQr(event: any) {
    if (formQuickReplyHandler && formQuickReplyHandler.handleQuickReply) {
      formQuickReplyHandler.handleQuickReply(event.detail.data as QuickReply);
    }
  }
</script>

<div
  on:mouseenter={disableBodyScroll}
  on:mouseleave={enableBodyScroll}
  role="region"
  aria-label="Chat Container"
>
  <div
    class="rizo-chat-legacy-box"
    class:rizo-chat-legacy-box-active={widgetIsActive}
    class:rizo-chat-legacy-box-inactive={!widgetIsActive}
  >
    {#if ENV === "dev"}
      <span
        class="rizo-chat-beta-label"
        class:rizo-chat-legacy-box-footer-active={widgetIsActive}
        class:rizo-chat-legacy-box-footer-inactive={!widgetIsActive}
      >
        Dev
      </span>
    {/if}
    <div class="rizo-chat-legacy-box-main-container">
      <div class="rizo-chat-legacy-box-body" bind:this={messageContainer}>
        {#if $fetchMessageLoading}
          <SkeletonView />
        {/if}

        {#if !$fetchMessageLoading && $botInfo?.settings?.welcomeMessage}
          <MessageRowComponent
            expanded={true}
            content={$botInfo?.settings?.welcomeMessage}
            role={"assistant"}
            {aiMessageConverter}
            {userMessageConverter}
          />
        {/if}
        {#if !$fetchMessageLoading && $botInfo?.quickReplies?.length > 0}
          <QuickReplyContainer quickReplies={$botInfo?.quickReplies} on:sendQr={sendQr} />
        {/if}
        {#each messageListData as message, i}
          <MessageRowComponent
            expanded={true}
            content={message.content}
            role={message.role}
            {aiMessageConverter}
            {userMessageConverter}
            assistantMessageId={message?.id || message?.metadata?.assistantMessageId}
            assistantFeedback={message?.assistantFeedback}
          />
        {/each}
        {#if $sendMessageLoading}
          <span class="rizo-ai-loader-container">
            <LogoLoader color={$botInfo?.settings?.widgetBackgroundColor || "#4b00cc"} />
          </span>
        {/if}
      </div>
    </div>
    <ChatForm
      on:messageReceived={scrollToBottom}
      bind:this={formQuickReplyHandler}
      expanded={false}
    />
    <div
      class="rizo-chat-legacy-box-footer"
      class:rizo-chat-legacy-box-footer-active={widgetIsActive}
      class:rizo-chat-legacy-box-footer-inactive={!widgetIsActive}
    >
      <a href="https://rizo.chat" target="_blank">Powered by Rizo</a>
    </div>
  </div>
</div>

<style>
  .rizo-chat-legacy-box {
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    box-shadow: 0px 2px 12px 0px #00000033;
    position: fixed;
    overflow: hidden;
    width: 500px;
    height: 200px;
    bottom: 30px;
    top: auto;
    left: auto;
    right: calc(50% - 250px);
    transition:
      height 0.3s ease,
      opacity 0.3s ease;
  }
  @media (max-width: 550px) {
    .rizo-chat-legacy-box {
      left: 10px;
      right: 10px;
      width: calc(100vw - 20px);
    }
  }

  .rizo-chat-legacy-box-active {
    opacity: 1;
    padding-top: 10px;
  }
  .rizo-chat-legacy-box-inactive {
    opacity: 0.8;
    backdrop-filter: blur(10px);
    height: 50px;
  }
  .rizo-chat-beta-label {
    position: absolute;
    width: 50px;
    height: 7px;
    left: -12px;
    top: 5px;
    background-color: #499cf8;
    color: #ffffff;
    transform: rotate(-45deg);
    font-size: 9px;
    text-align: center;
    padding: 2px 0px;
    z-index: 10;
    line-height: 7px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
      "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
  }
  /*-------------------------*/
  /*------- chat body -------*/
  /*-------------------------*/

  .rizo-chat-legacy-box-main-container {
    flex: 1;
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    position: relative;
  }
  .rizo-chat-legacy-box-body {
    /* background-color: #ffffff; */
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    position: relative;
    border-bottom: 1px solid #eeeeee;
  }

  .rizo-chat-legacy-box-footer {
    position: absolute;
    bottom: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 20px;
    transition:
      height 0.3s ease,
      opacity 0.3s ease;
  }
  .rizo-chat-legacy-box-footer-active {
    opacity: 1;
  }
  .rizo-chat-legacy-box-footer-inactive {
    opacity: 0;
  }
  .rizo-chat-legacy-box-footer > a {
    font-size: 10px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
      "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
    color: #4b00cc;
    text-decoration: unset;
    font-weight: bold;
  }
  .rizo-ai-loader-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    margin: 5px 0px 5px 10px;
  }
</style>
