import ChatWidget from "./ChatWidget.svelte";
// Define the custom element <rizo-chat-widget>
class RizoChatWidget extends HTMLElement {
    static get observedAttributes() {
        return ["apiToken"];
    }
    constructor() {
        super();
        // Store the reference to the Svelte component instance
        this.widgetInstance = null;
        const shadowRoot = this.attachShadow({ mode: "open" });
        // Create a container for the Svelte component
        const container = document.createElement("div");
        shadowRoot.appendChild(container);
        // Initialize the Svelte component instance
        this.widgetInstance = new ChatWidget({
            target: container,
            props: {
                apiToken: this.getAttribute("apiToken") || "",
            },
        });
    }
    // This method is called whenever an observed attribute is changed
    attributeChangedCallback(name, oldValue, newValue) {
        if (name === "apiToken" && this.widgetInstance) {
            // Update the Svelte component's props dynamically
            this.widgetInstance.$set({ apiToken: newValue });
        }
    }
    // This method is called when the element is removed from the DOM
    disconnectedCallback() {
        if (this.widgetInstance) {
            this.widgetInstance.$destroy(); // Properly destroy the Svelte component instance
        }
    }
}
// Register the custom element globally
customElements.define("rizo-chat-widget", RizoChatWidget);
