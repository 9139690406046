<script lang="ts">
  import {
    fetchMessageLoading,
    sendMessageLoading,
    messageList,
    botInfo,
    apiTokenValue,
  } from "../store";
  import { afterUpdate } from "svelte";
  import MessageRowComponent from "../MessageRowComponent.svelte";
  import reloadIcon from "../assets/images/reload.icon.svg";

  import ChatForm from "../ChatForm.svelte";
  import { initializeWidget, type Message, type QuickReply } from "../service/service";
  import SkeletonView from "../SharedComponent/SkeletonView.svelte";
  import QuickReplyContainer from "../QuickReplyContainer.svelte";
  import LogoLoader from "../SharedComponent/LogoLoader.svelte";
  import { ENV } from "../config/config";
  import PinChatBoxMessageRowComponent from "./PinnedChatBoxMessageRowComponent.svelte";
  import PinnedChatBoxMessageRowComponent from "./PinnedChatBoxMessageRowComponent.svelte";
  import PinnedChatForm from "./PinnedChatForm.svelte";
  import ReloadIcon from "../SharedComponent/ReloadIcon.svelte";
  import Logo from "../Logo.svelte";
  let messageListData: Message[] = [];
  let messageContainer: HTMLDivElement | null = null;
  let conversationIsReloading: boolean = false;

  let widgetIsActive: boolean = false;
  export let aiMessageConverter: any;
  export let userMessageConverter: any;

  messageList.subscribe((list) => {
    messageListData = list;
  });
  let formQuickReplyHandler: any;

  afterUpdate(() => {
    scrollToBottom();
  });

  function scrollToBottom() {
    if (messageContainer) {
      messageContainer.scrollTo({
        top: messageContainer.scrollHeight,
        behavior: "smooth",
      });
    }
  }

  async function handleClickReload() {
    conversationIsReloading = true;
    // await new Promise((resolve) => {
    //   setTimeout(() => {
    //     conversationIsReloading = false;
    //     resolve(true);
    //   }, 1500);
    // });

    localStorage.removeItem("conversationId");
    await initializeWidget($apiTokenValue);
    messageList.set([]);
    conversationIsReloading = false;
  }
  function sendQr(event: any) {
    if (formQuickReplyHandler && formQuickReplyHandler.handleQuickReply) {
      formQuickReplyHandler.handleQuickReply(event.detail.data as QuickReply);
    }
  }
</script>

<div role="region" aria-label="Chat Container">
  <div
    class="rizo-chat-legacy-box"
    class:rizo-chat-legacy-box-active={widgetIsActive}
    class:rizo-chat-legacy-box-inactive={!widgetIsActive}
  >
    <!-- {#if ENV === "dev"}
      <span
        class="rizo-chat-beta-label"
        class:rizo-chat-legacy-box-footer-active={widgetIsActive}
        class:rizo-chat-legacy-box-footer-inactive={!widgetIsActive}
      >
        Dev
      </span>
    {/if} -->
    <div class="rizo-chat-legacy-box-main-container">
      <div
        class="rizo-chat-legacy-reload-container"
        style="--element-background-color: {$botInfo?.settings?.widgetAppearanceBehavior
          ?.widgetFontColor || '#d2d2d2'};"
        class:rotating={conversationIsReloading}
      >
        <button
          on:click={handleClickReload}
          disabled={conversationIsReloading || $messageList.length === 0}
        >
          <ReloadIcon fillColor="#fff" />
        </button>
      </div>
      <div class="rizo-chat-legacy-box-body" bind:this={messageContainer}>
        <span style="min-height: 20px;" />
        {#if !$fetchMessageLoading && $botInfo?.settings?.welcomeMessage}
          <PinnedChatBoxMessageRowComponent
            expanded={true}
            content={$botInfo?.settings?.welcomeMessage}
            role={"assistant"}
            {aiMessageConverter}
            {userMessageConverter}
          />
        {/if}

        {#each messageListData as message, i}
          <PinnedChatBoxMessageRowComponent
            expanded={true}
            content={message.content}
            role={message.role}
            {aiMessageConverter}
            {userMessageConverter}
            assistantMessageId={message?.id || message?.metadata?.assistantMessageId}
            assistantFeedback={message?.assistantFeedback}
          />
        {/each}
        <!--   {#if !$sendMessageLoading}
       <span class="rizo-ai-loader-container">
          <LogoLoader
            color={$botInfo?.settings?.widgetAppearanceBehavior?.widgetFontColor || "#4b00cc"}
          />
        </span> 
         {/if}
        -->
        {#if $sendMessageLoading}
          <!-- <span class="rizo-ai-loader-container">
          <LogoLoader
            color={$botInfo?.settings?.widgetAppearanceBehavior?.widgetFontColor || "#4b00cc"}
          />
        </span> -->
          <span class="rizo-ai-dot-loader-container">
            <span class="rizo-ai-dot-loader-bot-icon">
              {#if $botInfo?.settings?.botImageUrl}
                <img src={$botInfo?.settings?.botImageUrl} alt="logo" />
              {:else}
                <Logo strokeColor={"#4b00cc"} width={"26"} height={"26"} />
              {/if}
            </span>
            <span
              style="--loader-color: {$botInfo?.settings?.widgetAppearanceBehavior
                ?.widgetFontColor || '#d2d2d2'};"
            ></span>
          </span>
        {/if}
      </div>
    </div>
    <PinnedChatForm
      on:messageReceived={scrollToBottom}
      bind:this={formQuickReplyHandler}
      expanded={false}
    />
  </div>
</div>

<style>
  .rizo-chat-legacy-box {
    margin: 10px auto;
    margin-top: 30px;
    /* background-color: #ffffff; */
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    /* box-shadow: 0px 2px 12px 0px #00000033; */
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 200px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    .rizo-chat-legacy-box {
      height: 225px;
      margin-left: 20px; /* This would be about 20px */
      margin-right: 20px; /* This would be about 15px */
      width: calc(100% - 40px);
    }
  }
  @media (min-width: 991px) {
    .rizo-chat-legacy-box {
      height: 250px;
    }
  }

  @media (max-width: 767px) {
    .rizo-chat-legacy-box {
      margin-left: 15px; /* This would be about 20px */
      margin-right: 15px; /* This would be about 15px */
      width: calc(100% - 30px);
    }
  }

  /* .rizo-chat-beta-label {
    position: absolute;
    width: 50px;
    height: 7px;
    left: -12px;
    top: 5px;
    background-color: #499cf8;
    color: #ffffff;
    transform: rotate(-45deg);
    font-size: 9px;
    text-align: center;
    padding: 2px 0px;
    z-index: 10;
    line-height: 7px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
      "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
  } */
  /*-------------------------*/
  /*------- chat body -------*/
  /*-------------------------*/

  .rizo-chat-legacy-box-main-container {
    flex: 1;
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    position: relative;
  }

  .rizo-chat-legacy-reload-container {
    position: absolute;
    display: flex;
    right: 10px;
    top: 10px;
    opacity: 0.5;
    transition:
      transform 1s,
      opacity 0.3s ease;
    z-index: 10;
  }

  .rizo-chat-legacy-reload-container:hover {
    opacity: 1;
  }
  .rizo-chat-legacy-reload-container > button {
    background-color: var(--element-background-color);
  }
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .rotating {
    animation: rotate 2s linear infinite;
  }
  .rizo-chat-legacy-reload-container > button {
    border: none;
    outline: none;
    height: 30px !important;
    width: 30px !important;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
  }

  .rizo-chat-legacy-reload-container > button:disabled {
    cursor: not-allowed;
  }
  /* .rizo-chat-legacy-box-main-container::before {
    z-index: 1;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 30px;  
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    pointer-events: none;  
  } */
  .rizo-chat-legacy-box-body {
    /* background-color: #ffffff; */
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    position: relative;
    /* border-bottom: 1px solid #eeeeee; */
  }

  /* .rizo-chat-legacy-box-footer-active {
    opacity: 1;
  }
  .rizo-chat-legacy-box-footer-inactive {
    opacity: 0;
  } */

  /* .rizo-ai-loader-container {
    
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    margin: 5px 0px 5px 10px;
  } */

  .rizo-ai-dot-loader-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    margin: 5px;
    gap: 10px;
  }

  .rizo-ai-dot-loader-container > span:last-child {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--loader-color);
  }
  .rizo-ai-dot-loader-bot-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #eaeaea;
    background-color: transparent;

    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .rizo-ai-dot-loader-bot-icon img {
    width: 30px;
    height: 30px;
    padding: 1px;
  }
</style>
