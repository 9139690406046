<script lang="ts">
  import { onMount } from "svelte";

  import { botInfo, showExternalInfoModal } from "./store";
  export let messageContainer: HTMLDivElement;

  let showExternalUserInfoBar = false;

  let lastScrollTop = 0;
  let isScrollingDown = false;

  const handleScroll = () => {
    if (
      localStorage.getItem("RizoChatShowExternalUserInfoBar") === "false" ||
      $showExternalInfoModal
    )
      return;

    const currentScrollTop = messageContainer.scrollTop;

    if (currentScrollTop > lastScrollTop) {
      isScrollingDown = true;
    } else {
      isScrollingDown = false;
    }

    if (isScrollingDown) {
      showExternalUserInfoBar = true;
    } else {
      showExternalUserInfoBar = false;
    }

    lastScrollTop = currentScrollTop;
  };

  onMount(() => {
    if (!$botInfo.conversationExtraInfo?.length) {
      return;
    }
    if (messageContainer) {
      messageContainer.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (messageContainer) {
        messageContainer.removeEventListener("scroll", handleScroll);
      }
    };
  });

  function disableUserInfoSetup() {
    localStorage.setItem("RizoChatShowExternalUserInfoBar", "false");
    showExternalUserInfoBar = false;
  }

  function openExternalUserInfoModal() {
    showExternalUserInfoBar = false;
    showExternalInfoModal.set(true);
  }
</script>

<div class="rizo-chat-external-user-info-bar" class:visible={showExternalUserInfoBar}>
  <p>Do you want submit your info?</p>
  <span class="rizo-chat-external-user-info-bar-actions">
    <button on:click={disableUserInfoSetup}>No</button>
    <button
      on:click={openExternalUserInfoModal}
      style="background-color: {$botInfo?.settings?.widgetBackgroundColor || '#4b00cc'};"
      >Yes</button
    >
  </span>
</div>

<style>
  .rizo-chat-external-user-info-bar {
    z-index: 9999;
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    background-color: #ffffffb3;
    box-shadow: 0px 2px 12px 0px #00000033;
    backdrop-filter: blur(10px);
    opacity: 0;
    transform: translateY(-100%);
    transition:
      transform 0.3s ease,
      opacity 0.3s ease;
  }
  .visible {
    opacity: 1;
    transform: translateY(0);
  }
  .rizo-chat-external-user-info-bar > p {
    margin: 0px;
    font-size: 12px;
  }

  .rizo-chat-external-user-info-bar-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
  }

  .rizo-chat-external-user-info-bar-actions > button:first-child {
    border: none;
    outline: none;
    height: 25px;
    width: 30px;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    background-color: #ababab;
    font-size: 10px;
    padding: 2px 10px;
  }
  .rizo-chat-external-user-info-bar-actions > button:last-child {
    border: none;
    outline: none;
    height: 25px;
    width: 45px;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-size: 10px;
    padding: 2px 10px;
  }
</style>
